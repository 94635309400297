body {
  background-color: #1c1c1c;
}

.dashboard-container {
  margin-top: 20px;
  color: white;
}
.danger-input::placeholder {
  color: #858484; /* Set your desired color */
  opacity: 1; /* Ensures the color is not faded */
}

.danger-input:-ms-input-placeholder { /* For Internet Explorer 10-11 */
  color: #858484;
}

.danger-input::-ms-input-placeholder { /* For Edge */
  color: #858484;
}

.danger-input:focus {
  background-color: #2e2e2e; /* Change the background color on focus */
  color: #fff; /* Ensure the text color is readable */
  border-color: #ff6b6b; /* Optionally, change the border color on focus */
  outline: none; /* Remove default outline */
}

/* .checkBox{
  margin-left: 15%;
} */

.danger-input {
  border: 2px solid #a3303c;
  box-shadow: none;
  color: #fff;
  background-color: #1c1c1c;
  resize: none; /* Prevent manual resizing */
  overflow: hidden; /* Hide overflow to handle auto resizing */
  line-height: 24px; 
  /* Adjust line height */
}

.auto-resize-textarea {
  transition: height 0.2s ease; /* Smooth resizing */
}

.generate-btn {
  background-color: #dc3545;
  border-color: #dc3545;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0 4px 10px rgba(220, 53, 69, 0.5);
}

.dropdown-container {
  margin-top: 0px;
}

.danger-select {
  background-color: #1c1c1c;
  color: #fff;
  border: 2px solid #8b2c36;
}

.danger-select:focus {
  border-color: #dc3545;
  box-shadow: none;
}

/* Response text should match the width of the textarea */
.responseText {
  border: 1px solid #dc3545;
  padding: 15px;
  background-color: #2a2a2a;
  color: white;
  border-radius: 4px;
  max-width: 100%; /* Ensure it is responsive and doesn't exceed the container */
}

/* Responsive adjustments */
/* @media (max-width: 768px) {
  .input-group {
    flex-direction: column;
  }

  .generate-btn {
    margin-top: 10px;
    width: 100%;
  }

  .dropdown-container {
    display: block;
  }
} */


