/* Full page dark red background with flickering effect */
.parent {
    width: 100vw;
    height: 100vh;
    background-color: #1c0101; /* Dark blood red */
    overflow: hidden;
    animation: flicker 5s infinite; /* Flickering animation */
  }
  
  /* Container with dark box and glowing red shadow */
  .boxx {
    background-color: #6e4246;
    border: 2px solid #fc3d3d; /* Devilish red border */
    box-shadow: 0 0 20px 10px rgba(255, 0, 0, 0.6); /* Red glow */
    transition: all 0.3s ease-in-out;
  }
  
  .boxx:hover {
    transform: scale(1.02); /* Slight zoom effect on hover */
    box-shadow: 0 0 30px 15px rgba(255, 0, 0, 0.8); /* Intensified red glow */
  }
  
  .container {
    max-width: 500px;
  }
  
  /* Devilish text with pulsing glow effect */
  h1.devil-text {
    font-size: 4rem;
    color: #fc3d3d; /* Blood red text */
    text-shadow: 0 0 20px #ff0000, 0 0 30px #ff0000; /* Glowing effect */
    animation: pulse 2s infinite; /* Pulsing glow animation */
  }
  
  /* Subtext with fade-in effect */
  .devil-subtext {
    color: #fcfcfc;
    font-style: italic;
    animation: text-fade-in 3s ease-in;
  }
  
  /* Input field styling with glowing focus */
  input {
    border: 2px solid #ff0000; /* Bright red border */
    border-radius: 30px;
    background-color: #3e0e0e; /* Dark input background */
    color: #ff4444; /* Light red text */
  }
  
  input:focus {
    border-color: #fc3d3d;
    box-shadow: 0 0 10px #ff0000; /* Glowing red on focus */
    background-color: #521515; /* Darker background on focus */
    color: #ffdddd;
  }
  
  /* Button with devilish red glow and hover effect */
  .btn-danger {
    background-color: #450606; /* Darker red button */
    border-color: #fc3d3d;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .btn-danger:hover {
    background-color: #2c0000;
    border-color: #ff0000;
    transform: scale(1.1); /* Enlarged hover effect */
    box-shadow: 0 0 5px 2px #ff4444; /* Red glow on hover */
  }
  
  /* Footer text styling with emphasis on WormFather */
  .footer-text {
    color: #fcfcfc;
    font-style: italic;
    margin-top: 20px;
  }
  
  .worm-father {
    color: #fc3d3d;
    font-weight: bold;
    text-shadow: 0 0 5px #4f0303, 0 0 10px #370303; /* Glowing red for emphasis */
  }
  @media(max-width: 365px) {
    h1.devil-text {
      font-size: 12vw ;

    }
    .devil-input{
      width: 100%;
      height: 20px;
    }
    
  }
  @media(max-width: 435px) {
    h1.devil-text {
      font-size: 13vw ;

    }
    .devil-input{
      width: 100%;
      height: 20px;
    }
    
  }
  /* Pulsing text shadow effect for devilish text */
  @keyframes pulse {
    0% {
      text-shadow: 0 0 10px #230202, 0 0 20px #630606;
    }
    50% {
      text-shadow: 0 0 20px #2b0505, 0 0 30px #3e0606;
    }
    100% {
      text-shadow: 0 0 10px #410303, 0 0 20px #380404;
    }
  }
  
  /* Flickering background effect */
  @keyframes flicker {
    0%, 100% {
      background-color: #301515;
    }
    50% {
      background-color: #0b0101; /* Flickering between dark and darker red */
    }
  }
  
  /* Text fade-in effect */
  @keyframes text-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  a{
    color: #f36060;
  }